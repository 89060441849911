@use '@/styles/utils/mixins.scss' as *;

.heroSwiper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    min-height: 450px;
  }
  @media screen and (max-width: 576px) {
    min-height: 303px;
  }

  .heroSwiperWrap {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    min-height: 500px;
    @include tab-des-min() {
      min-height: calc(100vh - 83px);
    }
    @media screen and (max-width: 767px) {
      min-height: 450px;
    }
    @media screen and (max-width: 576px) {
      min-height: 303px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 85%;
      background-color: rgba(5, 20, 31, 0.2);
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 30%;
      background: linear-gradient(
        180deg,
        rgba(5, 20, 31, 0) 20%,
        $kiaMidnightBlack 100%
      );
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
    .heroImage {
      z-index: -1;
      @media screen and (max-width: 1366px) {
        object-fit: cover;
      }
    }
    .heroBannerContentWrapper {
      max-width: 1280px;
      width: 100%;
      margin: auto;
      z-index: 1;

      @media screen and (max-width: 1366px) {
        margin: auto 56px;
      }
      .heroBannerContent {
        max-width: 460px;
        width: 100%;
        @media screen and (max-width: 576px) {
          display: none;
        }
        .headingElement {
          .titleXl {
            color: $kiaPolarWhite;
          }
          .btnCta {
            padding: 12px 30px;
          }
        }
      }
    }
  }
  .swiper-button-prev {
    left: 24px;
  }
  .swiper-button-next {
    right: 24px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 0;
    width: 48px;
    height: 48px;
    margin-top: -26px;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 48px;
    height: 48px;
  }
  .swiper-button-prev::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='49' viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.5'/%3E%3Cpath d='M29.3334 23.7865H21.2201L24.9467 20.0598L24.0001 19.1198L18.6667 24.4531L24.0001 29.7865L24.9401 28.8465L21.2201 25.1198H29.3334V23.7865Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  .swiper-button-next::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.5'/%3E%3Cpath d='M24 19.1198L23.06 20.0598L26.78 23.7865H18.6666V25.1198H26.78L23.06 28.8465L24 29.7865L29.3333 24.4531L24 19.1198Z' fill='white'/%3E%3C/svg%3E");
  }
  .swiper-pagination {
    bottom: 20px;
    @include tab-des-min() {
      bottom: 40px;
    }
  }
  .swiper-pagination-bullet {
    background-color: $kiaPolarWhite;
    opacity: 0.35;
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 16px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 16px)
    );
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

@media screen and (min-width: 576px) {
  .headingElementMobile {
    display: none;
  }
}

.headingElementMobile {
  background: var(--Primary-Colours-Kia-Midnight-Black, $kiaMidnightBlack);
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      color: $kiaPolarWhite;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .btnFirst {
      margin-right: 10px;
    }
    .btnCta {
      display: inline-block;
      padding: 12px 30px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
